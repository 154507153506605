<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Suggesting edits</h3>

	<p><img srcset="/docimages/suggest_edits-1.png 3x" class="k-help-img float-right">When editing a comment on a standard, click the <v-icon small class="mr-1">fas fa-edit</v-icon>SUGGEST EDITS button to suggest edits to the standard. Clicking this button will bring up a modified version of the item editor:</p>
	<img srcset="/docimages/suggest_edits-2.png 3x" class="k-help-img block">
	<ul>
		<li>Note that <b>changes you make here will <i>not</i> have any immediate effect on the saved CASE JSON!</b> You are only saving <i>suggested</i> edits, which can be applied later by a framework editor (see below).</li>
		<li>Make any changes you wish to suggest, to the Full Statement, Notes, Supplemental Information, or any other metadata field.</li>
		<li><img srcset="/docimages/suggest_edits-3.png 3x" class="k-help-img float-right">You may optionally use the highlight, add, or del controls to help draw attention to the changes you’re suggesting. You can also use the link or image buttons to add links or images here, but note that the CASE spec prohibits Full Statements and Notes from including links or images, so these elements will need to be removed before ultimately saving any edits to the item.</li>
		<li><img srcset="/docimages/suggest_edits-4.png 3x" class="k-help-img float-right">When you’re done, click <v-icon small class="mr-1">fas fa-save</v-icon>SAVE SUGGESTED EDITS.</li>
		<li>You will then find yourself back in the comment editor, with your suggested edits shown at the bottom of the editor:</li>
	</ul>
	<img srcset="/docimages/suggest_edits-6.png 3x" class="k-help-img block">
	<ul>
		<li><b>Important:</b> you must also click the button to <v-icon small class="mr-1">fas fa-check</v-icon>SAVE COMMENT to save your suggested edits. You can also enter anything you wish in the Comment text box, i.e. to explain why you’re suggesting this edit.</li>
	</ul>
	<p><b>To apply suggested edits:</b></p>
	<ul>
		<li>If you have <span v-html="link('roles_framework', 'editor rights')"></span> to the framework, when you view a comment with a suggested edit from an item’s card, you will see an APPLY SUGGESTED EDITS button:</li>
	</ul>
	<img srcset="/docimages/suggest_edits-5.png 3x" class="k-help-img block">
	<ul>
		<li>To apply the edit, make sure <span v-html="link('editing', 'edit mode')"></span> is on, then click this button. This will open the <span v-html="link('edit_item', 'item editor')"></span> with the suggested edits applied. Make any additional changes you wish, then click <v-icon small class="mr-1">fas fa-save</v-icon>SAVE CHANGES to update the CASE JSON for the item.</li>
		<li><img srcset="/docimages/suggest_edits-7.png 3x" class="k-help-img float-right"> Once this is done, the comment will show <v-icon small class="mr-1">fas fa-check-circle</v-icon>APPLIED, to let you know that the suggested change has been accepted and completed.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	